import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";

const Portfolio = () => {
	// init one ref to store the future isotope object
	const isotope = useRef();
	// store the filter keyword in a state
	const [filterKey, setFilterKey] = useState("*");
	const [imagesLoaded, setimagesLoaded] = useState(0);
	const [selectedProjectDetails, setSelectedProjectDetails] = useState();
	const [isOpen, setIsOpen] = useState(false);
	const [currentSelectedType, setcurrentSelectedType] = useState("");

	const htmlElement = document.getElementsByTagName("html")[0];
	const isRtl = htmlElement.getAttribute("dir") === "rtl";

	const filters = {
		DETAILED: "Details",
		MOCKUPS: "Mockups",
		YOUTUBE: "Youtube Videos",
		VIMEO: "Vimeo Videos",
	};

	const types = {
		IMAGE: "image",
		VIDEO: "video",
		DOCUMENT: "document",
	};

	const projectsData = [
		{
			title: "Volcanic Retail",
			type: "document",
			document: {
				projectInfo: `Volcanic Retail is a pioneering cloud-based platform designed to streamline the connection between retail brands and buyers aiming to introduce their products into retail stores. This platform optimizes the selection process by reducing an extensive assortment of retail brands to a select few, making it easier for retail buyers to find and communicate with potential partners through our interactive portal.
        
        The foundational goal of Volcanic Retail is to disrupt the traditional retail industry by creating a dynamic environment where new brands can emerge and thrive.
        
        In my role as a Senior Full Stack Developer, I was responsible for architecting and implementing the platform’s API, ensuring robust and scalable backend functionality. Additionally, I developed responsive and engaging front-end interfaces that facilitated seamless user experiences. My contributions were crucial in enabling effective communication between brands and buyers, significantly enhancing the platform's efficiency and user satisfaction.
        
        `,
				client: "Digiance LLC",
				technologies:
					"ReactJS, NodeJS, Typescript, AWS, MariaDB, Recoil, HubSpot CRM ",
				industry: "Ecommerce",
				date: "March 16, 2022",
				url: {
					name: "View",
					link: "https://volcanicretail.com/",
				},

				sliderImages: [
					"images/projects/VolcanicRetail/image1.png",
					"images/projects/VolcanicRetail/image2.png",
					"images/projects/VolcanicRetail/image3.png",
					"images/projects/VolcanicRetail/image4.png",
					"images/projects/VolcanicRetail/image5.png",
					"images/projects/VolcanicRetail/image6.png",
					"images/projects/VolcanicRetail/image7.png",
					"images/projects/VolcanicRetail/image8.png",
				],
			},

			thumbImage: "images/projects/VolcanicRetail/Main.png",

			categories: ["Details"],
			showClient: true,
			showImageText: false,
			imageText: "",
		},
		{
			title: "SmartGen AI",
			type: "document",
			document: {
				projectInfo: `
          SmartGen is an advanced SAAS AI tool designed to seamlessly transform your ideas into various forms of media, elevating your creative potential to new heights. Whether it's generating stunning visuals, completing conversational texts, or creating dynamic videos and music, SmartGen empowers users to experience the next generation of AI-driven content creation. 
        
          With features like text-to-image generation, intelligent chat completion, and personalized music creation, SmartGen opens up endless possibilities for content production. It enhances workflows and provides a powerful, automated solution for generating high-quality media.
          
          Whether you need captivating visuals, engaging videos, or custom soundtracks, SmartGen simplifies the process, allowing you to focus on your creative vision while it's efficient AI takes care of the rest.
          `,
				client: "Upwork Client",
				technologies:
					"Serverless, SSR/CSR, typescript, NodeJS, NextJS, Clerk, Stripe Payment, Prisma ORM, Postgress ",
				industry: "SAAS",
				date: "june 16, 2024",
				url: {
					name: "View",
					link: "https://smartgen.netlify.app/",
				},

				sliderImages: [
					"images/projects/SmartGen/image1.png",
					"images/projects/SmartGen/image2.png",
					"images/projects/SmartGen/image3.png",
					"images/projects/SmartGen/image4.png",
					"images/projects/SmartGen/image5.png",
					"images/projects/SmartGen/image6.png",
					"images/projects/SmartGen/image7.png",
				],
			},

			thumbImage: "images/projects/SmartGen/SMARTGENAppLogo.png",

			categories: ["Details"],
			showClient: false,
			showImageText: true,
			imageText: "SmartGen",
		},
		{
			title: "Order Right",
			type: "document",
			document: {
				projectInfo: `
          Developed "Order Right," a dynamic food delivery application that allows users to easily select and order from an extensive menu, complete with detailed dish descriptions and prices. The app integrates Stripe for secure and convenient payment processing, ensuring a seamless transaction experience. A robust authentication system was implemented to secure user accounts and safeguard personal and payment information. 
        
          Users can personalize their orders, while an administrative functionality enables managers to modify orders and update product details, keeping the menu current and aligned with inventory. I designed the user-friendly front-end, engineered a robust backend system to handle data management, secure payment transactions, and user authentication. Additionally, I integrated essential APIs for real-time updates and efficient operation. This project has significantly enhanced user satisfaction by streamlining the ordering process and improved business operations through effective management tools.`,
				client: "Upwork Client",
				technologies:
					"ReactJS, NodeJS, NextJS, AuthJS, Stripe Payment, Prisma ORM, Postgress ",
				industry: "Food Business",
				date: "March 16, 2024",
				url: {
					name: "View",
					link: "https://orderright.netlify.app/",
				},

				sliderImages: [
					"images/projects/OrderRight/image1.png",
					"images/projects/OrderRight/image2.png",
					"images/projects/OrderRight/image3.png",
					"images/projects/OrderRight/image4.png",
					"images/projects/OrderRight/image5.png",
					"images/projects/OrderRight/image6.png",
					"images/projects/OrderRight/image7.png",
					"images/projects/OrderRight/image8.png",
					"images/projects/OrderRight/image9.png",
				],
			},

			thumbImage: "images/projects/OrderRight/Main.png",

			categories: ["Details"],
			showClient: true,
			showImageText: false,
			imageText: "",
		},
		{
			title: "Talkative",
			type: "document",
			document: {
				projectInfo: `
          I developed "Talk a tive," a real-time messaging platform enabling instant private and group communications. Leveraging socket.io, the app facilitates immediate message exchange and displays typing indicators to enhance interaction. It supports robust group chat features with administrative controls and delivers notifications to keep users engaged.
          
          The backend, built with Node.js and Express, integrates with socket.io for efficient WebSocket management, ensuring scalable real-time data transmission. The frontend is crafted with modern JavaScript frameworks, offering a responsive user experience. Advanced security measures, including data encryption and secure authentication, protect user data and communications.
          `,
				client: "Upwork CLient",
				technologies:
					"ReactJS, NodeJS, Socket.io, web sockets, JWT advanced authetication, ExpressJS, MongoDB, React Context ",
				industry: "Social",
				date: "June 16, 2023",
				url: {
					name: "View",
					link: "https://talk-a-tive-3sur.onrender.com/",
				},

				sliderImages: [
					"images/projects/TalkATive/image4.png",
					"images/projects/TalkATive/image2.png",
					"images/projects/TalkATive/image3.png",
					"images/projects/TalkATive/image1.png",
				],
			},

			thumbImage: "images/projects/TalkATive/Main.png",

			categories: ["Details"],
			showClient: true,
			showImageText: false,
			imageText: "",
		},
		{
			title: "Bar Peak",
			type: "document",
			document: {
				projectInfo: `
          An app that transforms how people discover nightlife venues tailored to their preferences. The app serves as a personal guide, recommending spots—whether lively or more subdued atmospheres—in restaurants, clubs, and bars, matching each user's mood and taste. It provides live updates and feedback from other users, allowing for real-time insights into each venue's current popularity. It ensures that users can not only find places that fit their vibe but also choose based on whether they're looking for a crowded hotspot or a quieter evening, turning every night out into a unique and memorable experience.

          As a Senior Full Stack Developer, I was tasked with engineering an intuitive front-end and robust APIs for real-time data processing. This involved architecting systems that seamlessly integrate user feedback to dynamically update venue recommendations, enhancing the application’s responsiveness. My dual focus on front-end usability and back-end efficiency was instrumental in delivering a superior user experience that consistently exceeded expectations.
          `,
				client: "CounterFlow LLC",
				technologies:
					"ReactJS, React Native, NodeJS, GraphQL, Socket.IO, Maps, AWS, Mongo DB, Redux, Firebase",
				industry: "Entertainment",
				date: "Feb 05, 2021",
				url: {
					name: "View",
					link: "https://barpeak.com/",
				},

				sliderImages: [
					"images/projects/BarPeak/image2.png",
					"images/projects/BarPeak/image3.png",
				],
			},

			thumbImage: "images/projects/BarPeak/Main.png",

			categories: ["Details"],
			showClient: true,
			showImageText: false,
			imageText: "",
		},
	];

	// initialize an Isotope object with configs
	useEffect(() => {
		isotope.current = new Isotope(".portfolio-filter", {
			itemSelector: ".filter-item",
			layoutMode: "masonry",
			originLeft: !isRtl,
		});

		// cleanup
		return () => {
			isotope.current.destroy();
		};
	}, []);

	// handling filter key change
	useEffect(() => {
		if (imagesLoaded) {
			filterKey === "*"
				? isotope.current.arrange({ filter: `*` })
				: isotope.current.arrange({ filter: `.${filterKey}` });
		}
	}, [filterKey, imagesLoaded]);

	const handleFilterKeyChange = (key) => () => setFilterKey(key);

	const getKeyByValue = (value) => {
		return Object.keys(filters).find((key) => filters[key] === value);
	};

	const getFilterClasses = (categories) => {
		if (categories.length > 0) {
			let tempArray = [];
			categories.forEach((category, index) => {
				tempArray.push(getKeyByValue(category));
			});
			return tempArray.join(" ");
		}
	};

	return (
		<>
			<section
				id="portfolio"
				className={"section bg-light custom-portfolio-section"}
			>
				<div className={"container"}>
					{/* Heading */}
					{/* <p className="text-center mb-2 wow fadeInUp">
						<span className="bg-primary text-dark px-2">Portfolio</span>
					</p> */}
					<h2 className="text-10 fw-400 text-center mb-5 wow fadeInUp">
						Some of my most recent projects
					</h2>
					{/* Heading end*/}
					{/* Filter Menu */}
					{/* <ul
						className={
							"portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
						}
					>
						<li className="nav-item">
							<button
								className={"nav-link " + (filterKey === "*" ? "active" : "")}
								onClick={handleFilterKeyChange("*")}
							>
								All
							</button>
						</li>
						{Object.keys(filters).map((oneKey, i) => (
							<li className="nav-item" key={i}>
								<button
									className={
										"nav-link " + (filterKey === oneKey ? "active" : "")
									}
									onClick={handleFilterKeyChange(oneKey)}
								>
									{filters[oneKey]}
								</button>
							</li>
						))}
					</ul> */}
					{/* Filter Menu end */}
					<div className="portfolio wow fadeInUp">
						<div className="row portfolio-filter filter-container g-5">
							{projectsData.length > 0 &&
								projectsData.map((project, index) => (
									<div
										className={
											"col-sm-12 col-md-6 col-lg-4 filter-item " +
											getFilterClasses(project.categories)
										}
										key={index}
									>
										<div className="portfolio-box">
											<div className="portfolio-img responsive-container">
												<img
													onLoad={() => {
														setimagesLoaded(imagesLoaded + 1);
													}}
													className="img-fluid d-block portfolio-image custom-slider-image responsive-image"
													src={project.thumbImage}
													alt=""
												/>
												{project.showImageText && (
													<div class="responsive-text">{project.imageText}</div>
												)}

												<div
													className="portfolio-overlay"
													onClick={() => {
														setSelectedProjectDetails(projectsData[index]);
														setIsOpen(true);
													}}
												>
													<button className="popup-ajax stretched-link border-0 p-0 ">
														{" "}
													</button>
													<div className="portfolio-overlay-details">
														<p className="text-primary text-8">
															{project.type === types.DOCUMENT && (
																<i className="fas fa-file-alt"></i>
															)}
															{project.type === types.IMAGE && (
																<i className="fas fa-image"></i>
															)}
															{project.type === types.VIDEO && (
																<i className="fas fa-video"></i>
															)}
														</p>
														<h5 className="text-white text-5">
															{project?.title}
														</h5>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
			</section>
			{/* Modal */}
			{isOpen && (
				<ProjectDetailsModal
					projectDetails={selectedProjectDetails}
					setIsOpen={setIsOpen}
				></ProjectDetailsModal>
			)}
		</>
	);
};

export default Portfolio;
